<template>
    <ModalBox
        :is-active.sync="isModalActive"
        :is-loading="isLoadingRoles"
        @close="closeModal">
        <template #header>
            {{ $t(`admin.users.administrators.modal.editRole.header.title`) }}
        </template>
        <template>
            <b-field>
                <FSelect
                    v-model="selectedRoles"
                    :get-data="getRoles"
                    class="is-fullwidth"
                    field="name"
                    multiple>
                    <template #selected-option="{ option }">
                        <i
                            :style="{ backgroundColor: colorGenerator(selectedRoles, findIndexByValue(option)).next().value.color }"
                            class="value-icon"></i>
                        {{ option.name }}
                    </template>
                </FSelect>
            </b-field>
            
            <div class="column is-12">
                <div
                    v-if="isActive"
                    class="row-grid row-grid--checkbox-cover">
                    <div
                        :key="index"
                        v-for="(permission, index) in permissions">
                        <div class="columns is-marginless">
                            <template
                                v-for="(item, index) in colorGenerator(permissionsIncludesInRoles[permission.alias])">
                                <LTooltip
                                    :key="index"
                                    :label="item.item.name"
                                    position="is-top"
                                    type="is-dark">
                                    <div
                                        :style="{ backgroundColor: item.color }"
                                        class="column line-item"></div>
                                </LTooltip>
                            </template>
                        </div>
                        <CustomCheckbox
                            :value="selectedPermissions[permission.alias]"
                            :label="permission.alias"
                            class="is-small"
                            @input="selectPermission(permission.alias, $event)">
                        </CustomCheckbox>
                    </div>
                </div>
                <b-loading
                    v-else
                    :active="true"
                    :is-full-page="false"></b-loading>
            </div>
        </template>
        <template #footer>
            <b-button
                :disabled="!isAbleToSave"
                expanded
                type="is-success is-light"
                @click="editRole">
                {{ $t("common.buttons.save") }}
            </b-button>
            <b-button
                expanded
                type="is-danger is-light"
                @click="closeModal">
                {{ $t("common.buttons.cancel") }}
            </b-button>
        </template>
    </ModalBox>
</template>

<script>
  import CustomCheckbox from "@/components/Common/CustomCheckbox";
  import ModalBox from "@/components/Common/ModalBox";
  import FSelect from "@/components/Common/FSelect";
  import { Modal } from "@core/mixins";
  import { EDIT_ADMIN_ROLE, GET_PERMISSIONS, GET_ROLES } from "@core/store/action-constants";
  import { NotificationProgrammatic as Notification } from "buefy";
  import _cloneDeep from "lodash/cloneDeep";
  import _isEqual from "lodash/isEqual";
  import { mapActions, mapState } from "vuex";
  
  export default {
    name: "AdministratorsAddRoleModal",
    mixins: [Modal],
    components: {
      CustomCheckbox,
      ModalBox,
      FSelect
    },
    props: {
      admin: {
        type: Object,
        required: true
      }
    },
    data () {
      return {
        isLoadingRoles: false,
        selectedRoles: [],
        selectedPermissions: {}
      };
    },
    computed: {
      ...mapState("admin", {
        permissions: ({ roles }) => roles.permissions?.items || [],
        roles: ({ roles }) => roles.roles?.items || []
      }),

      flatPermissions () {
        return this.permissions.map(item => item.alias);
      },

      indexedRoles () {
        return _cloneDeep(this.roles.reduce((acc, item) => {
          acc[item.id] = item;

          return acc;
        }, {}));
      },

      computedRoles () {
        return this.selectedRoles.map(item => this.indexedRoles[item]).filter(item => item);
      },

      permissionsIncludesInRoles () {
        return this.permissions.reduce((acc, permission) => {
          acc[permission.alias] = this.computedRoles.filter(item => item.permissions.includes(permission.alias));
          return acc;
        }, {});
      },

      isConsistent () {
        return !this.inconsistentFields.length;
      },

      inconsistentFields () {
        return this.admin.permissions?.filter(permission => !this.flatPermissions.includes(permission)) || [];
      },

      isAbleToSave () {
        return this.isConsistent;
      }
    },
    methods: {
      selectPermission (alias, e) {
        this.selectedPermissions = { ...this.selectedPermissions, [alias]: e };
      },
      
      ...mapActions("admin", [
        GET_ROLES,
        GET_PERMISSIONS,
        EDIT_ADMIN_ROLE
      ]),

      async getPermissions () {
        await this.GET_PERMISSIONS();
      },

      findIndexByValue (value) {
        for (const i in this.computedRoles) {
          if (this.computedRoles.hasOwnProperty(i)) {
            if (_isEqual(this.computedRoles[i], value)) {
              return i;
            }
          }
        }
      },

      async editRole () {
        this.isLoadingRoles = true;

        await this.EDIT_ADMIN_ROLE({
          admins: [
            {
              adminId: this.admin.id,
              rolesIds: this.selectedRoles,
              permissions: Object.keys(this.selectedPermissions).filter(item => this.selectedPermissions[item])
            }
          ]
        });
  
        this.$buefy.toast.open({
          message: this.$t("admin.users.administrators.modal.editRole.messages.edit.success", { adminLogin: this.admin.login }),
          type: "is-success"
        });
  
        this.emitUpdate();
        this.closeModal();
        this.isLoadingRoles = false;
      },
  
      async getRoles () {
        const data = await this.GET_ROLES();
        return {
          count: data.count,
          items: data.items
        };
      },
  
      colorGenerator: function * (items, index) {
        const steps = 360 / items.length;
        const colorOffset = 60;
    
        if (index) {
          yield {
            item: items[index],
            color: `hsl(${ steps * index + colorOffset }, 60%, 60%)`
          };
        } else {
          for (const i in items) {
            if (items.hasOwnProperty(i)) {
              yield {
                item: items[i],
                color: `hsl(${ steps * i + colorOffset }, 60%, 60%)`
              };
            }
          }
        }
      }
    },
    watch: {
      isActive: {
        deep: true,
        immediate: true,
        async handler (value) {
          await this.getPermissions();

          if (value) {
            this.selectedRoles = this.admin.roles.map(item => item.id);

            for (const permission of this.flatPermissions) {
              if (this.admin.permissions.includes(permission)) {
                this.selectedPermissions[permission] = true;
              }
            }

            if (!this.isConsistent) {
              Notification.open({
                message: this.$t("common.errors.RESPONSE_ERROR", {
                  error: `<br><br><p><pre><code>${
                    this.$t("admin.users.administrators.modal.editRole.messages.consistent.fail", {
                      adminLogin: this.admin.login,
                      adminId: this.admin.id,
                      inconsistentFields: this.inconsistentFields
                    })
                  }`
                }),
                position: "is-top-right",
                type: "is-danger",
                hasIcon: true
              });

              this.closeModal();
              throw new Error(`
                Права пользователя ${ this.admin.login } (id: ${ this.admin.id }) не совпадают со списком прав.
                \n
                Список несовпадающих прав: ${ this.inconsistentFields }`
              );
            }
          }
        }
      }
    }
  };
</script>

<style lang="scss" scoped>
    ::v-deep {
      .modal-card {
        max-width: 800px;
        width: 100vw;
      }
    }

    .row-grid {
      &--checkbox-cover {
        grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
      }
    }

    :root {
        --hue: 0;
    }

    .line-wrapper {
        overflow: hidden;
        max-height: 6px;
        border-radius: 1rem;
    }

    .line-item {
        max-width: 25%;
        padding-top: 3px;
        padding-bottom: 3px;
    }

    .value-icon {
        padding: 6px;
        margin-right: 6px;
        border-radius: 50%;
    }
</style>

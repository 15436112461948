<template>
    <div>
        <ModalBox
            :is-active.sync="isActive"
            :model="{ ...form }"
            @submit="onSubmit"
            @close="close">
            <template #header>
                <h5 class="title is-5">
                    {{ $t(`admin.users.administrators.modal.${ isEdit ? "edit" : "addAdmin" }.header.title`) }}
                </h5>
            </template>
            <AdminProfile
                ref="ruleForm"
                :user.sync="localForm"
                :is-edit="isEdit"
                is-countries
                is-description
                is-worked-year
                :with-password-edit="!isEdit">
            </AdminProfile>
            <template #footer>
                <div class="container">
                    <div class="columns">
                        <div class="column">
                            <b-button
                                v-if="isEdit"
                                class="is-fullwidth is-info is-light"
                                expanded
                                @click="openPasswordModal">
                                {{ $t(`admin.profile.modal.footer.buttons.changePassword`) }}
                            </b-button>
                            <b-button
                                v-else
                                class="is-fullwidth is-info is-light"
                                expanded
                                @click="SET_EMPTY">
                                {{ $t("common.buttons.cancel") }}
                            </b-button>
                        </div>
                        <div class="column">
                            <b-button
                                expanded
                                native-type="submit"
                                class="is-success is-light">
                                {{ $t("admin.users.administrators.modal.edit.buttons.save") }}
                            </b-button>
                        </div>
                    </div>
                </div>
            </template>
            <b-loading
                v-model="isLoading"
                :is-full-page="false">
            </b-loading>
        </ModalBox>
        <PasswordModal
            :is-active.sync="isPasswordModalActive"
            :is-loading="isPasswordLoading"
            :password.sync="password"
            @submit="changePassword"
            @closed="emptyPasswordState">
        </PasswordModal>
    </div>
</template>

<script>
  import PasswordModal from "@/components/Admin/Users/common/PasswordModal";
  import AdminProfile from "@/components/Common/Admin/AdminProfile";
  import ModalBox from "@/components/Common/ModalBox";
  import {
    CREATE_ADMINISTRATOR,
    EDIT_ADMINISTRATOR,
    EDIT_PASSWORD,
    GET_ADMINISTRATOR,
    GET_ADMINISTRATORS
  } from "@core/store/action-constants";
  import {
    SET_EMPTY,
    SET_IS_ADMINISTRATORS_EDIT_MODAL_ACTIVE,
    UPDATE_ADMINISTRATORS_EDIT_FORM,
    UPDATE_MODAL,
    UPDATE_PASSWORD_FORM
  } from "@core/store/mutation-constants";
  import { mapFields } from "@vasiliyrusin/vue-mapfields";
  import _cloneDeep from "lodash/cloneDeep";
  import { mapActions, mapMutations, mapState } from "vuex";
  
  export default {
    name: "AdministratorsEditModal",
    
    components: {
      ModalBox,
      AdminProfile,
      PasswordModal
    },
    
    data () {
      return {
        localForm: {}
      };
    },
    computed: {
      ...mapState("admin/users/administrators/edit", [
        "administratorId",
        "form"
      ]),

      ...mapFields("admin/users/administrators/edit/password", {
        fields: [
          "isPasswordModalActive"
        ],
        base: "modal",
        action: UPDATE_MODAL
      }),

      ...mapFields("admin/users/administrators/edit/password", {
        fields: [
          "password"
        ],
        base: "form",
        action: UPDATE_PASSWORD_FORM
      }),

      isEdit () {
        return this.administratorId != null;
      },

      isActive: {
        get () {
          return this.$store.state.admin.users.administrators.edit.isModalActive;
        },

        set (value) {
          this.SET_IS_ADMINISTRATORS_EDIT_MODAL_ACTIVE(value);
        }
      },

      isLoading () {
        return this.$wait(`admin/users/administrators/edit/${ EDIT_ADMINISTRATOR }`) ||
          this.$wait(`admin/users/administrators/edit/${ CREATE_ADMINISTRATOR }`);
      },

      isPasswordLoading () {
        return this.$wait(`admin/users/administrators/edit/password/${ EDIT_PASSWORD }`);
      }
    },
    
    methods: {
      ...mapActions("admin/users/administrators", [
        GET_ADMINISTRATORS
      ]),

      ...mapActions("admin/users/administrators/edit", [
        GET_ADMINISTRATOR,
        EDIT_ADMINISTRATOR,
        CREATE_ADMINISTRATOR
      ]),

      ...mapActions("admin/users/administrators/edit/password", [
        EDIT_PASSWORD
      ]),

      ...mapMutations("admin/users/administrators/edit", [
        SET_IS_ADMINISTRATORS_EDIT_MODAL_ACTIVE,
        UPDATE_ADMINISTRATORS_EDIT_FORM,
        SET_EMPTY
      ]),

      ...mapMutations("admin/users/administrators/edit/password", {
        emptyPasswordState: SET_EMPTY
      }),

      openPasswordModal () {
        this.isPasswordModalActive = true;
      },

      close () {
        if (!this.isPasswordModalActive) {
          this.SET_EMPTY();
          this.localForm = {};
        }
      },

      async changePassword () {
        try {
          await this.EDIT_PASSWORD();
          this.emptyPasswordState();

          this.$buefy.toast.open({
            message: this.$t("admin.users.common.modal.password.messages.success"),
            type: "is-success"
          });
        } catch (error) {
          this.$buefy.toast.open({
            message: this.$t("admin.users.common.modal.password.messages.fail"),
            type: "is-danger"
          });
        }
      },

      massagesForErrors ({ message }, { login, email }) {
        throw new Error(this.$t(`admin.users.administrators.modal.edit.messages.fail.${ message }`, { login, email }));
      },

      async edit () {
        try {
          await this.EDIT_ADMINISTRATOR();
          return this.$t("admin.users.administrators.modal.edit.messages.success");
        } catch ({ errors }) {
          return this.massagesForErrors(errors[0], this.form);
        }
      },

      async create () {
        try {
          await this.CREATE_ADMINISTRATOR();
          return this.$t("admin.users.administrators.modal.edit.messages.successCreate");
        } catch ({ errors }) {
          return this.massagesForErrors(errors[0], this.form);
        }
      },

      async onSubmit () {
        try {
          let message;
          if (this.isEdit) {
            message = await this.edit();
          } else {
            message = await this.create();
          }

          this.close();
          this.GET_ADMINISTRATORS();
          this.$buefy.toast.open({ message, type: "is-success" });
        } catch ({ message }) {
          this.$buefy.toast.open({ message, type: "is-danger" });
        }
      }
    },
    watch: {
      async administratorId (value) {
        if (value) {
          this.localForm = null;
          await this.GET_ADMINISTRATOR();
          this.localForm = _cloneDeep(this.form);
        }
      },

      localForm: {
        deep: true,
        handler (value) {
          this.UPDATE_ADMINISTRATORS_EDIT_FORM(value);
        }
      },

      isPasswordModalActive (value) {
        this.SET_IS_ADMINISTRATORS_EDIT_MODAL_ACTIVE(!value);
      }
    }
  };
</script>

<style lang="scss" scoped>
::v-deep {
    .modal-card, .modal-card-body {
        overflow: visible;
    }
}
</style>

<template>
    <div class="wrapper">
        <AdministratorsFilters></AdministratorsFilters>
        <AdministratorsTable></AdministratorsTable>
        <AdministratorsEditModal></AdministratorsEditModal>
    </div>
</template>

<script>
  import AdministratorsEditModal from "@/components/Admin/Users/AdministratorsTab/AdministratorsEditModal";
  import AdministratorsFilters from "@/components/Admin/Users/AdministratorsTab/AdministratorsFilters.vue";
  import AdministratorsTable from "@/components/Admin/Users/AdministratorsTab/AdministratorsTable.vue";
  import { prepareBooleanString } from "@core/helpers/prepareBooleanString";
  import { DebounceUpdateByWatchedParams } from "@core/mixins";
  import { GET_ADMINISTRATORS } from "@core/store/action-constants";
  import { UPDATE_ADMINISTRATORS_FILTERS, UPDATE_ADMINISTRATORS_PAGINATION } from "@core/store/mutation-constants";
  import { mapFields } from "@vasiliyrusin/vue-mapfields";
  import { mapActions, mapMutations, mapState } from "vuex";
  
  export default {
    name: "UsersAdministrators",
    mixins: [DebounceUpdateByWatchedParams],
    components: {
      AdministratorsFilters,
      AdministratorsTable,
      AdministratorsEditModal
    },

    created () {
      const {
        page,
        perPage,
        loginOrIntId,
        isBlocked,
        email,
        skype,
        telegram
      } = this.$route.query;

      if (page) {
        this.page = Number(page);
      }

      if (perPage) {
        this.perPage = Number(perPage);
      }

      this.UPDATE_ADMINISTRATORS_FILTERS({
        ...loginOrIntId && { loginOrIntId },
        ...isBlocked && { isBlocked: prepareBooleanString(isBlocked) },
        ...email && { email },
        ...skype && { skype },
        ...telegram && { telegram }
      });
    },

    computed: {
      ...mapState("admin/users/administrators", {
        updateParams: ({ pagination, filters }) => ({ pagination, filters })
      }),

      ...mapFields("admin/users/administrators", {
        fields: ["perPage", "page"],
        base: "pagination",
        action: UPDATE_ADMINISTRATORS_PAGINATION
      })
    },
    methods: {
      ...mapActions("admin/users/administrators", [
        GET_ADMINISTRATORS
      ]),

      ...mapMutations("admin/users/administrators", [
        UPDATE_ADMINISTRATORS_FILTERS
      ]),

      async updated () {
        await this.GET_ADMINISTRATORS();

        const query = {
          ...this.updateParams.filters,
          ...this.updateParams.pagination
        };

        this.$router.push({
          query
        }).catch(_ => {
        });
      }
    }
  };
</script>

<style scoped lang="scss">
    @import "~bulma";
    @import "~buefy/src/scss/buefy";
</style>

<template>
    <div class="columns is-align-items-flex-end is-multiline">
        <div class="column is-2">
            <FField :label="$t('admin.users.administrators.filters.labels.nameOrLogin')">
                <FInput
                    v-model="loginOrIntId"
                    :placeholder="$t('admin.users.administrators.filters.labels.nameOrLogin')">
                </FInput>
            </FField>
        </div>
        <div class="column is-2">
            <FField :label="$t('admin.users.administrators.filters.labels.email')">
                <FInput
                    v-model="email"
                    :placeholder="$t('admin.users.administrators.filters.labels.email')">
                </FInput>
            </FField>
        </div>
        <div class="column is-2">
            <FField :label="$t('admin.users.administrators.filters.labels.skype')">
                <FInput
                    v-model="skype"
                    :placeholder="$t('admin.users.administrators.filters.labels.skype')">
                </FInput>
            </FField>
        </div>
        <div class="column is-2">
            <FField :label="$t('admin.users.administrators.filters.labels.telegram')">
                <FInput
                    v-model="telegram"
                    :placeholder="$t('admin.users.administrators.filters.labels.telegram')">
                </FInput>
            </FField>
        </div>
        <div class="column is-2">
            <b-field :label="$t(`admin.users.administrators.filters.labels.blocked`)">
                <FSelect
                    v-model="isBlocked"
                    :get-data="getIsBlocked"
                    :placeholder="$t(`admin.users.administrators.filters.values.all`)"
                    field="label"
                    prop="value">
                </FSelect>
            </b-field>
        </div>
        <div class="column has-text-right">
            <b-button
                type="is-success is-light"
                @click="openCreateModal">
                {{ $t("admin.users.administrators.filters.buttons.addAdmin") }}
            </b-button>
        </div>
    </div>
</template>

<script>
  import FSelect from "@/components/Common/FSelect";
  import { Filters } from "@core/mixins";
  import { SET_IS_ADMINISTRATORS_EDIT_MODAL_ACTIVE, UPDATE_ADMINISTRATORS_FILTERS } from "@core/store/mutation-constants";
  import { mapFields } from "@vasiliyrusin/vue-mapfields";
  import { mapMutations } from "vuex";
  import FField from "@/components/Common/FField";
  import FInput from "@/components/Common/FInput";
  
  export default {
    name: "AdministratorsFilters",
    mixins: [Filters],
    components: {
      FInput,
      FField,
      FSelect
    },

    computed: {
      ...mapFields("admin/users/administrators", {
        fields: [
          "loginOrIntId",
          "isBlocked",
          "email",
          "skype",
          "telegram"
        ],
        base: "filters",
        action: UPDATE_ADMINISTRATORS_FILTERS
      })
    },

    methods: {
      ...mapMutations("admin/users/administrators/edit", [
        SET_IS_ADMINISTRATORS_EDIT_MODAL_ACTIVE
      ]),

      openCreateModal () {
        this.SET_IS_ADMINISTRATORS_EDIT_MODAL_ACTIVE(true);
      },

      getIsBlocked () {
        const isBlocked = [
          {
            label: this.$t("admin.users.administrators.filters.values.blocked"),
            value: true
          }, {
            label: this.$t("admin.users.administrators.filters.values.notBlocked"),
            value: false
          }
        ];
        return {
          items: isBlocked,
          count: isBlocked.length
        };
      }
    }
  };
</script>

<style scoped></style>

<template functional>
    <span v-if="props.email">
        <a :href="props.protocol + props.email">
            {{ $options.methods.formatEmptyString(props.email) }}
        </a>
    </span>
    <span v-else>
        {{ $options.methods.formatEmptyString(null) }}
    </span>
</template>

<script>
  import { formatEmptyString } from "@core/filters";
  
  export default {
    name: "EmailOutput",
    props: {
      email: {
        type: String,
        default: null
      },
      
      protocol: {
        type: String,
        default: "mailto:"
      }
    },
    methods: {
      formatEmptyString
    }
  };
</script>

<style lang="scss" scoped>
    a {
        color: #000000;
        text-decoration: underline dotted;
    }
</style>

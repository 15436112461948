<template>
    <el-container
        class="flex justify-between flex-col">
        <div class="overflow-x-auto w-full">
            <el-table
                ref="FTable"
                v-loading="isLoading"
                v-infinite-scroll="infiniteScroll"
                :style="{'min-width': `${ minWidth || defaultWidth }px`}"
                v-bind="$attrs"
                :data="data"
                class="rounded f-table"
                :show-header="showHeader"
                :summary-method="getSummaries"
                :show-summary="showSummaries"
                @select="handleSelect"
                @select-all="handleSelect"
                @sort-change="updateSortings">
                <el-table-column
                    v-if="checkable"
                    type="selection">
                    <!--                    <template-->
                    <!--                        slot="header">-->
                    <!--                        <el-switch-->
                    <!--                            :active-value="1"-->
                    <!--                            :inactive-value="0"></el-switch>-->
                    <!--                    </template>-->
                    <!--                    <template>-->
                    <!--                        <el-switch v-model="isCheckedAll"></el-switch>-->
                    <!--                    </template>-->
                </el-table-column>
                <slot name="default"></slot>

                <template #empty>
                    <slot name="empty"></slot>
                </template>
                <template #append>
                    <slot name="append"></slot>
                </template>
            </el-table>
        </div>
        
        <FPagination
            :data="data"
            :per-page="perPage"
            :page-sizes="pageSizes"
            :current-page="currentPage"
            :total="total"
            :per-page-change="updatePerPage"
            class="mt-6"
            is-search
            hide-on-single-page
            @current-change="updateCurrentPage"
            @perPageChange="$emit('update:perPage', $event)">
        </FPagination>
    </el-container>
</template>

<script>
  import FPagination from "@/components/Common/FPagination";
  import { Table } from "@core/mixins";
  export default {
    name: "FTable",
    mixins: [Table],
    components: {
      FPagination
    },
    props: {
      pageSizes: {
        type: Array,
        default: () => [25, 50, 100]
      },
      
      checkedRows: {
        type: Array,
        default: null
      },
      minWidth: {
        type: [Number, String],
        default: 0
      },
      checkable: Boolean,
      showHeader: {
        type: Boolean,
        default: true
      }
    },
    
    mounted () {
      this.defaultWidth = (this.$refs.FTable?.columns?.length || 0) * 150;
    },
    
    data () {
      return {
        newCheckedRows: [],
        isCheckedAll: true,
        defaultWidth: 0
      };
    },
    
    methods: {
      infiniteScroll () {
        this.$emit("update:infiniteScroll");
      },
      handleSelect (selection) {
        this.$emit("update:checkedRows", selection);
      },
      toggleData () {
        this.newCheckedRows?.forEach(row => {
          this.$refs.FTable.toggleRowSelection(row, true);
        });
      }
    },
    watch: {
      checkedRows: {
        immediate: true,
        handler (rows) {
          this.newCheckedRows = rows;
          setTimeout(this.toggleData, 1); // без этого работать не будет
        }
      }
    }
  };
</script>

<style lang="scss" scoped>
    .el-container {
        ::v-deep {
            //.el-table__header-wrapper {
            //    .el-table__header {
            //        min-width: 992px;
            //    }
            //}
            //
            .el-table__body-wrapper {
                color: #162239;
                //overflow: auto;
                //
                //.el-table__body {
                //    min-width: 992px;
                //}
            }
            .cell {
                word-break: break-word;
            }

            .el-table thead {
                color: white;
            }

            .el-table td {
                height: 60px;
            }
            
            .el-table th {
                font-weight: 300;
                background-color: #9AA3B4;
                border: #9AA3B4 solid 1px;
                padding: 0;
                height: 60px;
                color: white;
                width: 100%;
            }

            .el-table__append-wrapper {
                min-width: 992px;
            }

            .options {
                .cell {
                    @apply flex w-full justify-around;
                }
            }
        }
    }

    ::v-deep {
        .el-checkbox {
            &__input.is-focus {
                .el-checkbox__inner {
                    border-color: #DCDFE6;
                }
            }

            &__input.is-checked {
                .el-checkbox__inner {
                    background: #FFFFFF;
                    border-color: #DCDFE6;

                    &:after {
                        transform: rotate(0deg) scaleY(1);
                        left: 5px;
                        top: 5px
                    }
                }

                +.el-checkbox__label {
                    color: inherit;
                }
            }

            &__input.is-indeterminate {
                .el-checkbox__inner {
                    background: #FFFFFF;
                    border-color: #DCDFE6;

                    &:hover, &:active, &:focus {
                        border-color: #DCDFE6;
                    }

                    &:after {
                        transform: rotate(0deg) scaleY(1);
                        left: 5px;
                        top: 5px;
                    }

                    &:before {
                        content: none;
                    }
                }

                +.el-checkbox__label {
                    color: inherit;
                }
            }

            &__inner {
                border-radius: 0;
                width: 20px;
                height: 20px;
                transition: none;

                &:hover, &:active, &:focus {
                    border-color: #DCDFE6;
                }

                &:after {
                    width: 8px;
                    height: 8px;
                    border: none;
                    background: rgba(22, 34, 57, 1);
                    transition: none;
                }
            }
        }
    }
</style>
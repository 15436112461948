<script>
  export default {
    name: "BasePagination",
        
    props: {
      currentPage: {
        type: Number,
        default: null
      },
      total: {
        type: Number,
        default: null
      },
      layout: {
        type: String,
        default: "pager, ->, prev, sizes, next"
      },
      background: {
        type: Boolean,
        default: true
      },
      perPage: {
        type: Number,
        default: 25
      },
      pageSizes: {
        type: Array,
        default: () => [25, 50, 100]
      },
      perPageChange: {
        type: Function,
        default: () => {}
      },
      hideOnSinglePage: {
        type: Boolean,
        default: false
      },
      isSearch: {
        type: Boolean,
        default: false
      }
    },
      
    computed: {
      isHidePagination () {
        const minPerPage = Math.min(...this.pageSizes);
        return this?.total < minPerPage;
      }
    },
    
    methods: {
      currentChange (value) {
        this.$emit("current-change", value);
      }
    }
  };
</script>
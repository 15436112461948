<template>
    <TablePlaceholder :data="administrators">
        <template slot-scope="{ items, count }">
            <FTable
                :current-page="page"
                :data="items"
                :loading="isLoading"
                :per-page.sync="perPage"
                :total="count"
                @update:sort="updateSortings($event)"
                @update:currentPage="updateCurrentPage">
                <el-table-column
                    v-slot="{ row }"
                    width="60px"
                    :label="$t(`admin.users.administrators.table.labels.id`)">
                    {{ formatEmptyString(row.intId) }}
                </el-table-column>

                <el-table-column
                    v-slot="{ row }"
                    :label="$t(`admin.users.administrators.table.labels.name`)">
                    {{ formatEmptyString(row.login) }}
                    <LTooltip
                        v-if="isSelf(row.id)"
                        :label="$t(`admin.users.administrators.filters.values.isSelf`)"
                        position="is-bottom"
                        type="is-info">
                        <b-icon
                            icon="portrait"
                            size="is-small"
                            type="is-primary"></b-icon>
                    </LTooltip>

                    <LTooltip
                        v-if="row.activity.isBlocked"
                        :label="$t(`admin.users.administrators.filters.values.blocked`)"
                        position="is-bottom"
                        type="is-dark">
                        <b-icon
                            class="has-text-light"
                            icon="ban"
                            size="is-small">
                        </b-icon>
                    </LTooltip>

                    <LTooltip
                        v-if="!row.activity.isConfirmed"
                        :label="$t(`admin.users.administrators.filters.values.emailUnConfirmedIcon`)"
                        position="is-bottom"
                        type="is-dark">
                        <b-icon
                            class="has-text-light line-through"
                            icon="envelope"
                            size="is-small">
                        </b-icon>
                    </LTooltip>
                    
                    <template v-if="row.name">
                        <br>
                        <small>{{ row.name }}</small>
                    </template>
                </el-table-column>

                <el-table-column
                    v-slot="{ row }"
                    :label="$t(`admin.users.administrators.table.labels.email`)">
                    <EmailOutput :email="row.contact.email"></EmailOutput>
                </el-table-column>

                <el-table-column
                    v-slot="{ row }"
                    :label="$t(`admin.users.administrators.table.labels.telegram`)">
                    <TelegramOutput :telegram="row.contact.telegram"></TelegramOutput>
                </el-table-column>

                <el-table-column
                    v-slot="{ row }"
                    :label="$t(`admin.users.administrators.table.labels.skype`)">
                    {{ formatEmptyString(row.skype) }}
                </el-table-column>

                <el-table-column
                    v-slot="{ row }"
                    :label="$t(`admin.users.administrators.table.labels.countries`)">
                    <Countries
                        :items="row.countries"
                        compact
                        tooltip-position="is-top">
                    </Countries>
                </el-table-column>

                <el-table-column
                    v-slot="{ row }"
                    :label="$t(`admin.users.administrators.table.labels.regDate`)">
                    {{ formatEmptyString(moment(row.activity.registeredAt)) }}
                </el-table-column>

                <el-table-column
                    v-slot="{ row }"
                    align="right">
                    <DropdownOptions
                        rounded
                        size="is-small">
                        <DropdownOption
                            v-if="!isSelf(row.id)"
                            icon="square-edit"
                            type="is-warning is-light"
                            @click="onEditAdmin(row.id)">
                            {{ $t(`admin.users.administrators.table.buttons.edit`) }}
                        </DropdownOption>

                        <DropdownOption
                            :icon="blockButtonIcon(row.activity.isBlocked)"
                            :type="blockButtonType(row.activity.isBlocked)"
                            @click="onToggleBlock(row)">
                            <template v-if="row.activity.isBlocked">
                                {{ $t(`admin.users.administrators.table.buttons.unblock`) }}
                            </template>
                            <template v-else>
                                {{ $t(`admin.users.administrators.table.buttons.block`) }}
                            </template>
                        </DropdownOption>

                        <DropdownOption
                            icon="user-shield"
                            type="is-warning is-light"
                            @click="toggleModalRole(row)">
                            {{ $t(`admin.users.administrators.table.buttons.roles`) }}
                        </DropdownOption>

                        <DropdownOption
                            v-if="!row.activity.isConfirmed"
                            icon="envelope"
                            type="is-success is-light"
                            @click="resendEmailByAdmin(row.id)">
                            {{ $t(`common.mailConfirmation.resendEmail.resend`) }}
                        </DropdownOption>
                    </DropdownOptions>
                </el-table-column>
            </FTable>

            <AdministratorsAddRoleModal
                v-if="modalAddRole"
                :admin="modalAddRoleAdmin"
                :is-active.sync="modalAddRole"
                @update="update">
            </AdministratorsAddRoleModal>
        </template>
    </TablePlaceholder>
</template>

<script>
  import AdministratorsAddRoleModal from "@/components/Admin/Users/AdministratorsTab/AdministratorsAddRoleModal";
  import TableOptionsMixin from "@/components/Admin/Users/TableOptionsMixin";
  import DropdownOption from "@/components/Common/DropdownOptions/DropdownOption";
  import DropdownOptions from "@/components/Common/DropdownOptions/DropdownOptions";
  import EmailOutput from "@/components/Common/EmailOutput";
  import TablePlaceholder from "@/components/Common/Table/TablePlaceholder";
  import Countries from "@/components/Common/Countries";
  import TelegramOutput from "@/components/Common/TelegramOutput";
  import { TableVuex } from "@core/mixins";
  import {
    BLOCK_ADMINISTRATOR,
    GET_ADMINISTRATORS,
    RESEND_EMAIL_BY_ADMIN,
    UNBLOCK_ADMINISTRATOR
  } from "@core/store/action-constants";
  import {
    SET_ADMINISTRATORS_EDIT_ID,
    SET_IS_ADMINISTRATORS_EDIT_MODAL_ACTIVE,
    UPDATE_ADMINISTRATORS_PAGINATION,
    UPDATE_ADMINISTRATORS_SORTS
  } from "@core/store/mutation-constants";
  import { mapFields } from "@vasiliyrusin/vue-mapfields";
  import { mapActions, mapMutations, mapState } from "vuex";
  import FTable from "../../../Common/FTable";

  export default {
    name: "AdministratorsTable",
    mixins: [TableOptionsMixin, TableVuex],
    components: {
      AdministratorsAddRoleModal,
      TablePlaceholder,
      DropdownOptions,
      DropdownOption,
      EmailOutput,
      TelegramOutput,
      Countries,
      FTable
    },

    data () {
      return {
        modalAddRole: false,
        modalAddRoleAdmin: {}
      };
    },

    computed: {
      ...mapFields("admin/users/administrators", {
        fields: ["perPage", "page"],
        base: "pagination",
        action: UPDATE_ADMINISTRATORS_PAGINATION
      }),

      ...mapState("admin/users/administrators", {
        administrators: state => state.administrators
      }),

      ...mapState("admin/profile", {
        selfId: ({ user }) => user.id
      }),

      isLoading () {
        return this.$wait(`admin/users/administrators/${ GET_ADMINISTRATORS }`);
      },

      isPaginated () {
        return this.administrators.count > this.perPage;
      }
    },

    methods: {
      ...mapActions("admin/users/administrators", [
        GET_ADMINISTRATORS,
        BLOCK_ADMINISTRATOR,
        UNBLOCK_ADMINISTRATOR,
        RESEND_EMAIL_BY_ADMIN
      ]),

      ...mapMutations("admin/users/administrators", [
        UPDATE_ADMINISTRATORS_SORTS
      ]),

      ...mapMutations("admin/users/administrators/edit", [
        SET_ADMINISTRATORS_EDIT_ID,
        SET_IS_ADMINISTRATORS_EDIT_MODAL_ACTIVE
      ]),

      updateSortings ({ sort, order }) {
        if (sort && order) {
          this.UPDATE_ADMINISTRATORS_SORTS({ sort, order });
          this.update();
        }
      },

      updateCurrentPage (page) {
        this.page = page;
      },
      
      coloredStatus (status) {
        return status ? "is-success" : "is-danger";
      },
      
      toggleModalRole (admin) {
        if (!this.modalAddRole) {
          this.modalAddRoleAdmin = admin;
          this.modalAddRole = true;
        }
      },
      
      onToggleBlock ({ id, login, activity }) {
        if (id) {
          const { isBlocked } = activity;
          const title = isBlocked
            ? this.$t("admin.users.administrators.confirm.isUnBlocked")
            : this.$t("admin.users.administrators.confirm.isBlocked");
          
          this.$buefy.dialog.confirm({
            title,
            confirmText: this.$t("admin.users.administrators.confirm.confirmButtonText"),
            cancelText: this.$t("admin.users.administrators.confirm.cancelButtonText"),
            type: "is-info is-light",
            onConfirm: async () => {
              if (isBlocked) {
                try {
                  await this.UNBLOCK_ADMINISTRATOR(id);
                  this.toastSuccess(this.$t("admin.users.administrators.messages.unblock.success", { adminLogin: login }));
                  this.update();
                } catch (err) {
                  const message = this.$t(`admin.users.administrators.table.messages.fail.${ err.errors[0].message }`);
                  this.toastFail(this.$t("admin.users.administrators.messages.unblock.fail", { adminLogin: login }), message);
                }
              } else {
                try {
                  await this.BLOCK_ADMINISTRATOR(id);
                  this.toastSuccess(this.$t("admin.users.administrators.messages.block.success", { adminLogin: login }));
                  this.update();
                } catch (err) {
                  const message = this.$t(`admin.users.administrators.table.messages.fail.${ err.errors[0].message }`);
                  this.toastFail(this.$t("admin.users.administrators.messages.block.fail", { adminLogin: login }), message);
                }
              }
            }
          });
        }
      },

      isSelf (id) {
        return id === this.selfId;
      },

      async resendEmailByAdmin (id) {
        try {
          await this.RESEND_EMAIL_BY_ADMIN(id);

          this.$buefy.toast.open({
            message: this.$t("common.mailConfirmation.resendEmail.success"),
            type: "is-success"
          });
        }
        catch (error) {
          this.$buefy.toast.open({
            message: this.$t("common.mailConfirmation.resendEmail.fail"),
            type: "is-danger"
          });
        }
      },

      onEditAdmin (id) {
        this.SET_ADMINISTRATORS_EDIT_ID(id);
        this.SET_IS_ADMINISTRATORS_EDIT_MODAL_ACTIVE(true);
      },

      update () {
        this.GET_ADMINISTRATORS();
      }
    }
  };
</script>

<style lang="scss" scoped>
    .line-through {
        position: relative;

        &:after {
            content: "";
            top: 0;
            left: 0;
            width: 4px;
            height: 120%;
            position: absolute;
            transform: translate(5px, -2px) rotateZ(-45deg);
            background-image: linear-gradient(90deg, #B5B5B5 50%, white 50%);
        }
    }
</style>
